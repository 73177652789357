import React, { FC } from "react"
import { SubmitHandler, useForm, Controller, useFieldArray } from "react-hook-form"
import Typography from "@material-ui/core/Typography"
import TextField from "@material-ui/core/TextField"
import IconButton from "@material-ui/core/IconButton"
import Button from "@material-ui/core/Button"
import Grid from "@material-ui/core/Grid"
import DeleteIcon from "@material-ui/icons/Delete"
import { NewsletterSubscriptions, NewsletterSubscriptions_newsletterSubscriptions_options } from "../../../__generated__/NewsletterSubscriptions"

export interface Inputs {
  title: string
  options: Array<NewsletterSubscriptions_newsletterSubscriptions_options>
}

interface IProps {
  data?: NewsletterSubscriptions["newsletterSubscriptions"]
  onSubmit: SubmitHandler<Inputs>
}

const NewsletterSubscriptionForm: FC<IProps> = ({ onSubmit, data }) => {
  const { control, handleSubmit } = useForm<Inputs>({
    shouldFocusError: true,
    defaultValues: {
      title: data?.title ?? "",
      options: data?.options ?? [],
    },
  })

  const { fields, append, remove } = useFieldArray<NewsletterSubscriptions_newsletterSubscriptions_options, "fieldId">({
    control,
    name: "options",
    keyName: "fieldId",
  })

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Grid container spacing={6}>
        <Grid item xs={12}>
          <Controller
            name="title"
            control={control}
            rules={{ required: true }}
            render={(field) => <TextField fullWidth label="Title" {...field} />}
          />
        </Grid>

        <Grid container item spacing={2} xs={12}>
          <Grid item xs={12} container justify="space-between">
            <Typography variant="h6">Subscription options</Typography>
            <Button type="button" onClick={() => append({})}>
              Add Option
            </Button>
          </Grid>
          {fields.map((field, index) => (
            <Grid container item xs={12} key={field.fieldId} alignItems="flex-end">
              <Grid item xs>
                <IconButton onClick={() => remove(index)}>
                  <DeleteIcon />
                </IconButton>
              </Grid>
              <Grid item xs={3}>
                <Controller
                  name={`options.${index}.mCode`}
                  control={control}
                  rules={{ required: true }}
                  defaultValue={field.mCode ?? ""}
                  render={(field) => <TextField label="mCode" fullWidth {...field} />}
                />
              </Grid>
              <Grid item xs={8}>
                <Controller
                  name={`options.${index}.text`}
                  control={control}
                  rules={{ required: true }}
                  defaultValue={field.text ?? ""}
                  render={(field) => <TextField label="Text" fullWidth {...field} />}
                />
              </Grid>
            </Grid>
          ))}
        </Grid>
        <Grid item container justify="flex-end">
          <Button type="submit" color="primary" variant="contained">
            Submit
          </Button>
        </Grid>
      </Grid>
    </form>
  )
}

export default NewsletterSubscriptionForm
