import React, { FC, useState, useEffect } from "react"
import TextField from "@material-ui/core/TextField"
import Grid from "@material-ui/core/Grid"
import Paper from "@material-ui/core/Paper"
import Box from "@material-ui/core/Box"
import MenuItem from "@material-ui/core/MenuItem"
import { useMutation, useQuery } from "@apollo/client"
import LoadingView from "@cbs-sports/sports-shared-client/build/cjs/components/LoadingView"
import { newPalette } from "@cbs-sports/sports-shared-client/build/cjs/utils/style-utils"
import Layout from "../components/Layout"
import { ADMIN_GAME_INSTANCES_QUERY } from "../queries"
import {
  AdminGameInstances,
  AdminGameInstances_gameInstances_edges_node,
  AdminGameInstances_gameInstances_edges_node_seasons_edges_node,
  AdminGameInstances_gameInstances_edges_node_seasons_edges_node_segments_edges_node,
} from "../../../__generated__/AdminGameInstances"
import { toNodes } from "../../utils/misc-utils"
import { NEWSLETTER_SUBSCRIPTIONS_QUERY } from "../../App/queries"
import { NewsletterSubscriptions, NewsletterSubscriptionsVariables } from "../../../__generated__/NewsletterSubscriptions"
import NewsletterSubscriptionForm, { Inputs } from "./NewsletterSubscriptionForm"
import { ADMIN_UPSERT_NEWSLETTER_MUTATION } from "../queries"
import {
  AdminUpsertNewsletterMutation as MutationResult,
  AdminUpsertNewsletterMutationVariables as MutationVariables,
} from "../../../__generated__/AdminUpsertNewsletterMutation"
import { SubmitHandler } from "react-hook-form"

type SportGame = {
  sportType: string
  gameInstanceUid: string
}

type SportGameGroups = Map<string, Set<string>>

const NewsletterManager: FC = () => {
  const [selectedSportType, setSelectedSportType] = useState<string>("")
  const [selectedGameInstanceUid, setSelectedGameInstanceUid] = useState<string | null>(null)
  const skip = !(selectedSportType || selectedGameInstanceUid)
  useEffect(() => {
    setSelectedGameInstanceUid(null)
  }, [selectedSportType])

  const { data: gameInstanceData, loading: gameInstanceLoading } = useQuery<AdminGameInstances>(ADMIN_GAME_INSTANCES_QUERY)
  const { data: newsletterSubscriptionsData, loading: newsletterSubscriptionLoading, refetch } = useQuery<
    NewsletterSubscriptions,
    NewsletterSubscriptionsVariables
  >(NEWSLETTER_SUBSCRIPTIONS_QUERY, {
    notifyOnNetworkStatusChange: true,
    variables: {
      sportType: selectedSportType,
      gameInstanceUid: selectedGameInstanceUid,
      exactMatch: true,
    },
    skip,
    fetchPolicy: "no-cache",
  })
  const [upsertNewsletterSubscription, { loading: mutationLoading }] = useMutation<MutationResult, MutationVariables>(
    ADMIN_UPSERT_NEWSLETTER_MUTATION,
  )

  const onSubmit: SubmitHandler<Inputs> = (inputData) => {
    const variables: MutationVariables = {
      id: newsletterSubscriptionsData?.newsletterSubscriptions?.id,
      data: {
        title: inputData.title,
        options: inputData.options,
        gameInstanceUid: !!selectedGameInstanceUid ? selectedGameInstanceUid : null,
        sportType: selectedSportType,
      },
    }
    upsertNewsletterSubscription({
      variables,
    }).then(() =>
      refetch({
        sportType: selectedSportType,
        gameInstanceUid: selectedGameInstanceUid,
        exactMatch: true,
      }),
    )
  }

  const loading = gameInstanceLoading || newsletterSubscriptionLoading || mutationLoading

  const gameInstanceNodes = toNodes<AdminGameInstances_gameInstances_edges_node>(gameInstanceData?.gameInstances)
  const sportGames: Array<SportGame> = gameInstanceNodes.map((gi) => {
    const [season] = toNodes<AdminGameInstances_gameInstances_edges_node_seasons_edges_node>(gi.seasons)
    const [segment] = !!season ? toNodes<AdminGameInstances_gameInstances_edges_node_seasons_edges_node_segments_edges_node>(season.segments) : []
    return {
      sportType: segment?.sportType ?? "",
      gameInstanceUid: gi.uid,
    }
  })

  const sportGameGroups: SportGameGroups = sportGames.reduce((map, current) => {
    const existing = map.get(current.sportType)
    if (existing) {
      existing.add(current.gameInstanceUid)
    } else {
      const initialMap = new Set<string>()
      initialMap.add(current.gameInstanceUid)
      map.set(current.sportType, initialMap)
    }
    return map
  }, new Map<string, Set<string>>())

  const sportTypeOptions = [...sportGameGroups.keys()].map((sport) => (
    <MenuItem key={sport} value={sport}>
      {sport}
    </MenuItem>
  ))

  const gameInstanceOptions =
    selectedSportType &&
    [...(sportGameGroups.get(selectedSportType) ?? [])].map((gi) => (
      <MenuItem key={gi} value={gi}>
        {gi}
      </MenuItem>
    ))

  return (
    <Layout title="Newsletter Subscriptions">
      {loading && <LoadingView color={newPalette.blue70} />}
      {!loading && (
        <>
          <Grid container spacing={4}>
            <Grid item xs={6}>
              <TextField label="Sport Type" value={selectedSportType ?? ""} onChange={(e) => setSelectedSportType(e.target.value)} select fullWidth>
                {sportTypeOptions}
              </TextField>
            </Grid>
            <Grid item xs={6}>
              <TextField
                label="Game Instance"
                value={selectedGameInstanceUid ?? ""}
                onChange={(e) => setSelectedGameInstanceUid(e.target.value)}
                select
                fullWidth
                SelectProps={{ displayEmpty: true }}
                InputLabelProps={{ shrink: true }}
              >
                <MenuItem value="">No Game Instance</MenuItem>
                {gameInstanceOptions}
              </TextField>
            </Grid>
            <Grid item xs>
              {!skip && (
                <Paper>
                  <Box px={4} py={6}>
                    <NewsletterSubscriptionForm data={newsletterSubscriptionsData?.newsletterSubscriptions} onSubmit={onSubmit} />
                  </Box>
                </Paper>
              )}
            </Grid>
          </Grid>
        </>
      )}
    </Layout>
  )
}

export default NewsletterManager
